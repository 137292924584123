import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { verifySentEmailAction } from "../utils/user";

export default function EmailVerification() {
  const [Verifying, setVerifying] = useState(true);

  const searchParams = useSearchParams();
  const oobCode = searchParams.get("oobCode");
  const action = searchParams.get("mode");

  const verify = verifySentEmailAction(action, oobCode);

  useEffect(() => {
    if (!isNaN(verify.code)) setVerifying(false);
  }, [verify.code]);

  return (
    <Container>
      <div className="box">
        <h1>Hey there,</h1>
        <p>
          It's the first step toward your AbroadMates Journey.
          <br />
          {action === "verifyEmail"
            ? "Give us a moment to verify your email."
            : action === "recoverEmail"
            ? ""
            : action === "resetPassword"
            ? ""
            : "Unknown Request Type."}
        </p>
        <small>{Verifying ? "Verifying..." : verify.message}</small>
      </div>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  min-height: 100dvh;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: var(--aj-gray-100);
  & > .box {
    background-color: var(--aj-white-200);
    padding: 24px;
    border-radius: 24px;
    text-align: center;
    width: min(500px, 100%);
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    & > h1 {
      font-size: 1.75rem;
      font-weight: 500;
    }
    & > a {
      color: var(--aj-primary);
      font-size: 2rem;
      text-decoration: none;
    }
  }
`;
