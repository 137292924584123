import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function LogoIcon({ link = true }) {
  const Data = {
    logo: "/logos/logo.svg",
    alt: "abroad mate logo",
    href: "/",
  };

  const version = "beta";

  return (
    <Container>
      {link ? (
        <Link to={Data.href} className="logo">
          <span className="logo_con">
            <img src={Data.logo} alt={Data.alt} />
          </span>
          <span className="small">{version}</span>
        </Link>
      ) : (
        <div className="logo">
          <span className="logo_con">
            <img src={Data.logo} alt={Data.alt} />
          </span>
          <span className="small">{version}</span>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  & > a,
  & > div {
    &.logo {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      color: var(--aj-primary);
      text-decoration: none;
      gap: 2px;
      & > .logo_con {
        overflow: hidden;
        position: relative;
        display: block;
        width: min(96.828px, 8rem);
        aspect-ratio: 96.828/36;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > .small {
        font-size: 0.6rem;
        line-height: 100%;
        font-weight: 600;
        text-align: right;
        width: min(96.828px, 8rem);
      }
    }
  }
`;
