import { BsFolder2Open } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { MdGroups, MdMoveToInbox } from "react-icons/md";
import { TbHome } from "react-icons/tb";

export const LeftSidebarOptions = [
  {
    name: "Dashboard",
    href: "dashboard",
    icon: <TbHome />,
  },
  {
    name: "My Documents",
    href: "documents",
    icon: <MdMoveToInbox />,
  },
  // {
  //   name: "My Application",
  //   href: "applications",
  //   icon: <BsFolder2Open />,
  // },
  // {
  //   name: "Task",
  //   href: "tasks",
  //   icon: <FaTasks />,
  // },
  // {
  //   name: "Group",
  //   href: "group",
  //   icon: <MdGroups />,
  // },
  // {
  //   name: "Statistics",
  //   href: "statistics",
  //   icon: <MdOutlineAddChart />,
  // },
];
