import React from "react";
import styled from "styled-components";

export default function NotFound() {
  return (
    <Container>
      <div className="box">
        <h1>
          The page you are looking for is not available as if now.
          <br />
          Please check back or contact support.
          <br />
        </h1>
        <a href="/">Back to home.</a>
      </div>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  min-height: 100dvh;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: var(--aj-gray-100);
  & > .box {
    background-color: var(--aj-white-200);
    padding: 24px;
    border-radius: 24px;
    text-align: center;
    width: min(500px, 100%);
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    & > h1 {
      font-size: 1.75rem;
      font-weight: 500;
    }
    & > a {
      color: var(--aj-primary);
      font-size: 2rem;
      text-decoration: none;
    }
  }
`;
