import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { db } from "../../utils/firebase";
import { daysOfWeek } from "../../data/calender";
import {
  generateBookingSlots,
  getCurrentWeekNumber,
  GETUTCDateString,
} from "../../utils/converts/date";
import { conflictingSlotWithUTC } from "../../utils/checkers/time";
import useApp from "../../store";
import ModalWrapper from "../modals/ModalWrapper";
import { BsFiles } from "react-icons/bs";
import { FaStripe } from "react-icons/fa";

export const Calendar = ({ mentorId, data }) => {
  const { loggedInStatus, profileData } = useApp();
  const [currentDate] = useState(new Date());
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const bkashNo = "01333333333";
  const [selectedDate, setSelectedDate] = useState(tomorrow);
  const [selectedSlot, setSelectedSlot] = useState();
  const [AvailabilityLoading, setAvailabilityLoading] = useState(false);
  // const [MentorAvailability, setMentorAvailability] =
  //   useState(availabilityDefault);

  const [ChosenDuration, setChosenDuration] = useState(30);
  // const [BookedSlots, setBookedSlots] = useState([]);

  // const [Min30Slots, setMin30Slots] = useState([]);
  // const [Min60Slots, setMin60Slots] = useState([]);
  const [Available30Slots, setAvailable30Slots] = useState([]);
  const [Available60Slots, setAvailable60Slots] = useState([]);

  const [CheckoutModal, setCheckoutModal] = useState(false);
  const [IsBkashPay, setIsBkashPay] = useState(false);

  const [TnxID, setTnxID] = useState();
  const [SendNumber, setSendNumber] = useState();
  const [ConfirmLoading, setConfirmLoading] = useState(false);
  const [ConfirmSuccess, setConfirmSuccess] = useState(undefined);
  const [ConfirmError, setConfirmError] = useState();

  // Get calendar data
  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      const prevMonthDay = new Date(year, month, -i);
      days.unshift({
        date: prevMonthDay,
        isCurrentMonth: false,
        isSelectable: false,
      });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const isSelectable =
        date > today && date <= new Date(today.setDate(today.getDate() + 8));

      days.push({
        date,
        isCurrentMonth: true,
        isSelectable,
      });
    }

    // Add next month's days to complete the grid
    const remainingDays = 42 - days.length; // 6 rows * 7 days = 42
    for (let i = 1; i <= remainingDays; i++) {
      const nextMonthDay = new Date(year, month + 1, i);
      days.push({
        date: nextMonthDay,
        isCurrentMonth: false,
        isSelectable: false,
      });
    }

    return days;
  };

  const days = getDaysInMonth(currentDate);
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateClick = (day) => {
    if (day.isSelectable) {
      setSelectedDate(day.date);
    }
  };

  useEffect(() => {
    const fetchAvailability = async () => {
      setAvailabilityLoading(true);
      setSelectedSlot(undefined);
      setAvailable30Slots([]);
      setAvailable60Slots([]);
      let min30 = [],
        min60 = [];
      try {
        const docRef = doc(db, "mentors_availability", mentorId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data) {
            // setMentorAvailability(data);
            const slots = data[daysOfWeek[selectedDate.getDay()]];

            if (slots.length > 0) {
              slots.forEach((slot) => {
                min30 = [
                  ...min30,
                  ...generateBookingSlots(slot, 30, selectedDate),
                ];
              });
              // setMin30Slots(min30);
              slots.forEach((slot) => {
                min60 = [
                  ...min60,
                  ...generateBookingSlots(slot, 60, selectedDate),
                ];
              });
              // setMin60Slots(min60);
            }
          }
        } else console.info("No data found.");

        //
        const getBookingsData = async () => {
          const bookingRef = collection(db, "mentors_bookings");
          const bookingQuery = query(
            bookingRef,
            where("mentor", "==", mentorId),
            where("utcDate", "==", GETUTCDateString(selectedDate))
          );
          const arr = [];
          const querySnapshot = await getDocs(bookingQuery);
          querySnapshot.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          return arr;
        };
        Promise.resolve(getBookingsData()).then((res) => {
          // setBookedSlots(res);

          const f30 =
            min30?.length > 0 &&
            min30.filter(
              (el) => !conflictingSlotWithUTC(el.start, el.end, res) && el
            );
          const f60 =
            min60?.length > 0 &&
            min60.filter(
              (el) => !conflictingSlotWithUTC(el.start, el.end, res)
            );
          setAvailable30Slots(f30?.length > 0 ? [...new Set(f30)] : []);
          setAvailable60Slots(f60?.length > 0 ? [...new Set(f60)] : []);
        });
      } catch (error) {
        console.error("Error fetching availability:", error);
      } finally {
        setAvailabilityLoading(false);
      }
    };
    fetchAvailability();
  }, [mentorId, selectedDate]);

  const handleBooking = (e) => {
    e.preventDefault();
    if (loggedInStatus) {
      if (profileData?.stepCompletedProfile === 7) {
        setConfirmLoading(true);
        const object = {
          mentor: mentorId,
          mentee: profileData.uid,
          utcDate: GETUTCDateString(selectedDate),
          start: selectedSlot.start,
          end: selectedSlot.end,
          duration: ChosenDuration,
          charge: ChosenDuration === 30 ? data?.rate?.m30 : data?.rate?.m60,
          month: currentDate.getMonth(),
          year: currentDate.getFullYear(),
          week: getCurrentWeekNumber(),
          paymentMethod: IsBkashPay ? "Bkash" : "Stripe",
          bkashTnxID: TnxID,
          bkashNo: SendNumber,
          paymentConfirm: "pending",
          payoutConfirm: "pending",
          mentorHandledStatus: "pending",
          adminHandledStatus: "pending",
        };
        try {
          const docRef = doc(db, "mentors_bookings", currentDate.toISOString());
          setDoc(
            docRef,
            JSON.parse(
              JSON.stringify({
                ...object,
              })
            ),
            { merge: false }
          );
          setConfirmSuccess(true);
          setConfirmError(false);
        } catch (error) {
          console.error(error);
          setConfirmSuccess(false);
          setConfirmError(error);
        } finally {
          setCheckoutModal(false);
          setConfirmLoading(false);
        }
      } else window.open("/dashboard/", "_self");
    } else {
      sessionStorage.comingFrom = window.location.pathname;
      window.open("/sign-in", "_self");
    }
  };

  const handleBookingModal = () => {
    if (loggedInStatus) {
      if (profileData?.stepCompletedProfile === 7) {
        setCheckoutModal(true);
      } else window.open("/dashboard/", "_self");
    } else {
      sessionStorage.comingFrom = window.location.pathname;
      window.open("/sign-in", "_self");
    }
  };

  const handleCopy = (title) => {
    title && navigator.clipboard.writeText(title);
  };

  return (
    <>
      <Container>
        <h2>Select Duration</h2>
        <div className="button-group">
          <button
            className={`${ChosenDuration === 30 ? "active" : ""}`}
            onClick={() => setChosenDuration(30)}
          >
            30 Minutes {data?.rate?.m30 ? `($${data?.rate?.m30})` : "$--"}
          </button>
          <button
            className={`${ChosenDuration === 60 ? "active" : ""}`}
            onClick={() => setChosenDuration(60)}
          >
            60 Minutes {data?.rate?.m60 ? `($${data?.rate?.m60})` : "$--"}
          </button>
        </div>
        <div className="block-container">
          <div className="calender-container">
            <div className="calendar-header">
              <button className="">&lt;</button>
              <h2 className="">
                {months[currentDate.getMonth()]} {currentDate.getFullYear()}
              </h2>
              <button className="">&gt;</button>
            </div>

            <div className="calendar-body">
              {React.Children.toArray(
                weekDays.map((day) => <div className="day">{day}</div>)
              )}

              {days.map((day, index) => {
                const isSelected =
                  selectedDate &&
                  day.date.toDateString() === selectedDate.toDateString();

                return (
                  <button
                    key={index}
                    onClick={() => handleDateClick(day)}
                    disabled={!day.isSelectable}
                    className={`
                date
                ${!day.isCurrentMonth ? "cm" : "td"}
                ${day.isSelectable ? "selectable" : "not-selectable"}
                ${isSelected ? "selected" : ""}
              `}
                  >
                    {day.date.getDate()}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="available-slots">
            {AvailabilityLoading ? (
              <p>Loading...</p>
            ) : (
              ChosenDuration === 30 &&
              Available30Slots.length === 0 && <p>No slots Available!</p>
            )}
            {ChosenDuration === 30 &&
              React.Children.toArray(
                Available30Slots.map((slot, index) => (
                  <button
                    className={
                      JSON.stringify(slot) === JSON.stringify(selectedSlot)
                        ? "active"
                        : ""
                    }
                    onClick={() => setSelectedSlot(slot)}
                  >
                    {new Date(slot.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                    {/* -
                  {new Date(slot.end).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })} */}
                  </button>
                ))
              )}
            {ChosenDuration === 60 && Available60Slots.length === 0 && (
              <p>No slots Available!</p>
            )}
            {ChosenDuration === 60 &&
              React.Children.toArray(
                Available60Slots.map((slot, index) => (
                  <button
                    className={
                      JSON.stringify(slot) === JSON.stringify(selectedSlot)
                        ? "active"
                        : ""
                    }
                    onClick={() => setSelectedSlot(slot)}
                  >
                    {new Date(slot.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                    {/* -
                  {new Date(slot.end).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })} */}
                  </button>
                ))
              )}
          </div>
        </div>
        <div className="bookACall">
          <button
            className={`url aj-btn-primary`}
            disabled={
              !(selectedDate && selectedSlot && mentorId !== profileData?.uid)
            }
            onClick={handleBookingModal}
          >
            Book a Call
            {mentorId === profileData?.uid &&
              "(You are not allowed to book a call with yourself)"}
          </button>
        </div>
      </Container>

      <ModalWrapper
        show={CheckoutModal}
        onClose={() => {
          setCheckoutModal(false);
        }}
        title={
          IsBkashPay
            ? `Total ${Math.ceil(
                Number(
                  ChosenDuration === 30 ? data?.rate?.m30 : data?.rate?.m60
                ) * 120
              )}`
            : "Select Payment Option:"
        }
      >
        <ModalBody className={IsBkashPay ? "bkash" : "select"}>
          {IsBkashPay ? (
            <form onSubmit={handleBooking}>
              <div className="fields">
                <h3 className="">Pay Using</h3>
                <p className="">Bkash</p>
              </div>
              <div className="fields">
                <h3 className="title">Account Number</h3>
                <p className="title">Account Type</p>
                <h3>
                  {bkashNo}{" "}
                  <button onClick={() => handleCopy(bkashNo)}>
                    <BsFiles />
                  </button>
                </h3>
                <p>Personal</p>
              </div>
              <div className="fields">
                <h3>Phone no:</h3>
                <input
                  type="tel"
                  value={SendNumber}
                  onChange={(e) => setSendNumber(e.target.value)}
                  placeholder="+8801XXXXXXXXX"
                  required
                />
              </div>
              <div className="fields">
                <h3>Tnx ID:</h3>
                <input
                  type="text"
                  value={TnxID}
                  onChange={(e) => setTnxID(e.target.value)}
                  placeholder="ASA1312"
                  required
                />
              </div>
              <div className="buttons">
                <button
                  className="outline"
                  type="reset"
                  onClick={() => setCheckoutModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="primary"
                  type="submit"
                  disabled={!(SendNumber && TnxID)}
                >
                  {ConfirmLoading ? "Loading..." : "Confirm Order"}
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="button-c">
                <button className="">
                  <FaStripe className="icon" /> Stripe
                </button>
              </div>
              <div className="button-c">
                <button className="" onClick={() => setIsBkashPay(true)}>
                  <img
                    className="icon"
                    src="/icons/bkash.svg"
                    alt="bkash icon"
                  />{" "}
                  Bkash
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </ModalWrapper>
    </>
  );
};

const Container = styled.section`
  padding: 24px;
  & > h2 {
    font-size: 2.25rem;
    text-align: center;
    color: var(--aj-primary);
  }
  & > .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto;
    border-radius: 12px;
    overflow: hidden;
    & > button {
      padding: 12px 18px;
      background-color: var(--aj-gray-200);
      font-size: 1rem;
      font-weight: 500;
      border: 1px solid var(--aj-gray-400);
      &.active {
        border-color: var(--aj-primary);
        background-color: var(--aj-primary);
        color: var(--aj-white);
      }
      &:first-child {
        border-radius: 12px 0 0 12px;
        border-right: none;
      }
      &:last-child {
        border-radius: 0 12px 12px 0;
        border-left: none;
      }
    }
  }
  & > .block-container {
    width: min(800px, 100%);
    margin: 0 auto;
    padding: 24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    height: 378px;
    @media (max-width: 500px) {
      grid-template-columns: 100%;
    }
    & > .calender-container {
      width: calc(4px * 72);
      background-color: var(--aj-gray-100);
      border-radius: 16px;
      padding: 16px;
      color: var(--aj-dark);
      margin: 0 auto;
      & > .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        & > button {
          color: var(--aj-gray-400);
          &:hover {
            color: var(--aj-dark);
          }
        }
        & > h2 {
          font-size: 20px;
          font-weight: 600;
        }
      }
      & > .calendar-body {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 4px;
        & > .day {
          /* text-center text-gray-400 text-sm py-2 */
          text-align: center;
          color: var(--aj-gray-400);
          font-size: 12px;
          padding: 8px 0;
        }
        & > .date {
          /* h-8 w-8 rounded-full text-sm flex items-center justify-center */
          height: 32px;
          aspect-ratio: 1/1;
          border-radius: 8px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.cm {
            color: var(--aj-gray-200);
          }
          &.selectable {
            font-weight: 700;
            &:hover {
              color: var(--aj-primary);
            }
          }
          &.selected {
            color: var(--aj-white) !important;
            background-color: var(--aj-primary);
          }
          &.not-selectable {
            color: var(--aj-gray-400);
            cursor: not-allowed;
          }
          &.td {
            color: var(--aj-dark);
          }
        }
      }
    }
    & > .available-slots {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      overflow-y: auto;
      & > button {
        padding: 12px 18px;
        background-color: var(--aj-gray-100);
        font-size: 1.25rem;
        font-weight: 500;
        border: 1px solid var(--aj-gray-200);
        border-radius: 12px;
        width: min(200px, 100%);
        margin: 0 auto;
        &.active {
          border-color: var(--aj-primary);
          background-color: var(--aj-primary);
          color: var(--aj-white);
        }
      }
      & > p {
        margin: auto;
        text-transform: capitalize;
      }
    }
  }
  & > .bookACall {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 0 0 24px;
    & > .url {
      padding: 12px 24px;
      border-radius: 12px;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
`;

const ModalBody = styled.section`
  &.select {
    display: flex;
    align-items: center;
    & > .button-c {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      &:not(:last-child) {
        border-right: 1px solid var(--aj-primary);
      }
      & > button {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: auto;
        background-color: var(--aj-gray-100);
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: 600;
        & > .icon {
          font-size: 20px;
          width: 20px;
          height: auto;
        }
      }
    }
  }
  &.bkash {
    & > form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      & > .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        background-color: var(--aj-white-100);
        color: var(--aj-gray-400);
        padding: 12px 16px;
        border-radius: 16px;
        & > h3 {
          font-size: 1.25rem;
        }
        & > p {
          font-size: 1.25rem;
          text-align: right;
        }
        & > .title {
          font-size: 0.875rem;
          color: var(--aj-gray-300);
        }
        & > input {
          text-align: right;
          font-size: 1.25rem;
          border: none;
          background-color: var(--aj-white);
          padding: 4px 0;
          border-radius: 8px;
          color: var(--aj-dark);
        }
      }
      & > .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 1rem;
        & > button {
          padding: 6px 12px;
          border-radius: 8px;
          border: 2px solid var(--aj-primary);
          font-size: 1rem;
          &.primary {
            background-color: var(--aj-primary);
            color: var(--aj-white);
          }
          &:disabled {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;
