export const initialState = {
  loggedInStatus: undefined,
  profileData: null,
  userIs: false,
  lastUpdated: new Date(),
  slotInfo: {},
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "login":
      return { ...state, loggedInStatus: payload };
    case "profileData":
      return { ...state, profileData: payload };
    case "userIs":
      return { ...state, userIs: payload };
    case "lastUpdated":
      return { ...state, lastUpdated: payload };
    case "setSlot":
      return { ...state, slotInfo: payload };
    default:
      return state;
  }
};

export default reducer;
