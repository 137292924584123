import { FaCalendarAlt } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { IoPersonCircleSharp } from "react-icons/io5";
import { MdMoveToInbox } from "react-icons/md";

export const LeftSidebarOptions = [
  {
    name: "Overview",
    href: "dashboard",
    icon: <IoPersonCircleSharp />,
  },
  {
    name: "My Profile",
    href: "documents",
    icon: <MdMoveToInbox />,
  },
  {
    name: "Schedule",
    href: "schedule",
    icon: <FaCalendarAlt />,
  },
  {
    name: "Availability",
    href: "availability",
    icon: <FaCalendarAlt />,
  },
  {
    name: "My Mentees",
    href: "mentees",
    icon: <IoIosSend />,
  },
  // {
  //   name: "Group",
  //   href: "group",
  //   icon: <MdGroups />,
  // },
  // {
  //   name: "Statistics",
  //   href: "statistics",
  //   icon: <MdOutlineAddChart />,
  // },
];
