// export function isValidTimeSlot(start, end) {
//   const [startHour, startMinute] = start.split(":").map(Number);
//   const [endHour, endMinute] = end.split(":").map(Number);

//   const startTotal = startHour * 60 + startMinute;
//   const endTotal = endHour * 60 + endMinute;

//   return endTotal - startTotal >= 30;
// }

function getHourAndMinute(isoString) {
  const date = new Date(isoString);
  const hours = date.getHours(); // for local time
  const minutes = date.getMinutes();
  return [hours, minutes];
}

export function conflictingSlotWithUTC(start, end, slots) {
  for (const slot of slots) {
    if (start === slot.start) {
      return true;
    }
  }
  return false;
}

export function conflictingSlot(newStart, newEnd, existingSlots) {
  const [newStartHour, newStartMinute] = newStart.split(":").map(Number);
  const [newEndHour, newEndMinute] = newEnd.split(":").map(Number);
  const newStartTotal = newStartHour * 60 + newStartMinute;
  const newEndTotal = newEndHour * 60 + newEndMinute;

  for (const slot of existingSlots) {
    const [slotStartHour, slotStartMinute] = getHourAndMinute(slot.start).map(
      Number
    );
    const [slotEndHour, slotEndMinute] = getHourAndMinute(slot.end).map(Number);
    const slotStartTotal = slotStartHour * 60 + slotStartMinute;
    const slotEndTotal = slotEndHour * 60 + slotEndMinute;

    // Conflict if the new slot overlaps any existing slot
    if (newStartTotal < slotEndTotal && newEndTotal > slotStartTotal) {
      return true;
    }
  }

  return false;
}

export function isValidTimeSlot(newStart, newEnd, existingSlots) {
  // Convert new slot times to minutes after midnight
  const [newStartHour, newStartMinute] = newStart.split(":").map(Number);
  const [newEndHour, newEndMinute] = newEnd.split(":").map(Number);
  const newStartTotal = newStartHour * 60 + newStartMinute;
  const newEndTotal = newEndHour * 60 + newEndMinute;

  // Check if the duration is at least 30 minutes
  if (conflictingSlot(newStart, newEnd, existingSlots)) {
    return {
      verdict: false,
      message: (
        <>
          <strong>
            Conflicting time slot
            {/* {existingSlots.length > 1 && "s"} */}
          </strong>
        </>
      ),
    };
  }
  if (newEndTotal - newStartTotal < 30)
    return {
      verdict: false,
      message: (
        <>
          Time Slot must be at least <strong>30 minutes</strong>
        </>
      ),
    };

  // Check for conflicts with each existing slot

  return {
    verdict: true,
    message: (
      <>
        Time Slot is a <strong>valid time slot.</strong>
      </>
    ),
  };
}

export function validTimeSlotDuration(start, end) {
  const [startHour, startMinute] = start.split(":").map(Number);
  const [endHour, endMinute] = end.split(":").map(Number);

  const startTotal = startHour * 60 + startMinute;
  const endTotal = endHour * 60 + endMinute;

  return endTotal - startTotal >= 30 ? endTotal - startTotal : 0;
}

export function convertTimeToISOString(timeStr) {
  const [hours, minutes] = timeStr.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date.toISOString();
}
