import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useApp from "../store";
import StudentDashboard from "../views/DashboardStudent";
import AdminDashboard from "../views/DashboardAdmin";
import MentorDashboard from "../views/DashboardMentor";
import DashboardSudo from "../views/DashboardSudo";

export default function Dashboard() {
  const { loggedInStatus, userIs } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInStatus) {
      if (sessionStorage?.comingFrom) navigate(sessionStorage.comingFrom);
      else navigate("/sign-in");
    }
  }, [loggedInStatus, navigate]);

  return (
    <Container>
      {
        {
          admin: <AdminDashboard />,
          mentor: <MentorDashboard />,
          requested: <MentorDashboard />,
          student: <StudentDashboard />,
          sudo: <DashboardSudo />,
        }[userIs]
      }
    </Container>
  );
}

const Container = styled.main`
  background-color: var(--aj-gray-200);
  height: 100dvh;
`;
