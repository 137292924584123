import React, { useEffect, useState } from "react";
import useApp from "../store";
import { useNavigate } from "react-router-dom";

export default function Checkout() {
  const { loggedInStatus } = useApp();
  const navigate = useNavigate();
  const [BookingInfo, setBookingInfo] = useState();

  useEffect(() => {
    const sd = sessionStorage.globSlotInfo;
    if (!loggedInStatus) {
      navigate("/sign-in");
    } else if (!(sd && typeof JSON.parse(sd) === "object")) {
      window.history.back() || navigate("/");
    } else setBookingInfo(JSON.parse(sd));
  }, [loggedInStatus, navigate]);

  const func = () => {
    // const docRef = doc(db, "mentors_bookings", currentDate.toISOString());
    // setDoc(
    //   docRef,
    //   JSON.parse(
    //     JSON.stringify({
    //       ...object,
    //     })
    //   ),
    //   { merge: false }
    // );
  };
  return <div>{JSON.stringify(BookingInfo)}</div>;
}
