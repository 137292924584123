import React from "react";
import { FaPen } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { res_sm } from "../../settings";
import useApp from "../../store";
import MentorCommon from "../../components/blocks/MentorCommon";
import { MentorServices } from "../../data/MentorServiceData";

export default function Dashboard() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { profileData } = useApp();
  const searchParams = new URLSearchParams(search);
  const MS = MentorServices;

  return (
    <Container className="">
      {/* <section className="aj-book-session">
        <div className="aj-book-session-content">
          <p>MentorShip</p>
          <h2>
            Start Getting Students <br />
            Passionate students from all over the world!
          </h2>
          <button onClick={() => navigate("/mentors/")}>
            Update Availability <FaPlay />
          </button>
        </div>
        <div className="graphics"></div>
      </section> */}
      {/* {profileData?.stepCompletedProfile &&
      profileData?.stepCompletedProfile === 7 ? (
        false
      ) : (
      )} */}

      <div className="contain">
        <MentorCommon
          Data={profileData}
          Services={() => {
            let ser = [];
            for (let el of MS) {
              if (profileData?.services?.includes(el.value)) {
                ser.push(el);
              }
            }
          }}
          mainImage="small"
        />
      </div>

      {profileData?.stepCompletedProfile &&
      profileData?.stepCompletedProfile === 7 ? (
        <div className="aj-profileEdit">
          <button
            onClick={() => {
              searchParams.set("tab", "documents");
              navigate(`/dashboard/?${searchParams.toString()}`);
            }}
          >
            Edit <FaPen />
          </button>
        </div>
      ) : (
        <section className="aj-profileComplete">
          <div className="indicator">
            {React.Children.toArray(
              [...[...Array.from({ length: 7 })].map((_, ind) => ind + 1)].map(
                (pos) => (
                  <div
                    className={
                      profileData?.stepCompletedProfile <= pos ? "active" : ""
                    }
                  ></div>
                )
              )
            )}
          </div>
          <div className="aj-profileComplete-content">
            <h2>
              Complete Your Profile:{" "}
              <span>
                {profileData?.stepCompletedProfile
                  ? Math.round((profileData.stepCompletedProfile / 7) * 100)
                  : "0"}
                %
              </span>{" "}
              Done
            </h2>
            <button
              onClick={() => {
                searchParams.set("tab", "documents");
                navigate(`/dashboard/?${searchParams.toString()}`);
              }}
            >
              Complete Now
            </button>
          </div>
        </section>
      )}
    </Container>
  );
}
const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @media (max-width: ${res_sm}px) {
  }
  & > .contain {
    width: min(744px, 100%);
    margin: 0 auto;
  }
  & > .aj-profileComplete {
    width: 100%;
    background-color: var(--aj-white-200);
    padding: 12px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    position: sticky;
    inset: 0;
    top: auto;
    bottom: 20px;
    & > .indicator {
      display: flex;
      width: 100%;
      gap: 8px;
      & > * {
        flex: 1;
        height: 4px;
        border-radius: 4px;
        background-color: var(--aj-gray-200);
      }
    }
    & > .aj-profileComplete-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
      & > h2 {
        color: var(--aj-primary);
        font-size: 1.25rem;
      }
      & > button {
        background-color: var(--aj-primary);
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 500;
        padding: 8px 24px;
        color: var(--aj-white);
      }
    }
  }
  & > .aj-profileEdit {
    background-color: var(--aj-white-200);
    padding: 8px;
    display: inline;
    margin-left: auto;
    position: sticky;
    right: 20px;
    bottom: 20px;
    border-radius: 12px;
    & > button {
      background-color: var(--aj-primary);
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      padding: 8px 24px;
      color: var(--aj-white);
    }
  }
  & > .aj-book-session {
    width: 100%;
    background-color: var(--aj-primary);
    color: var(--aj-white-100);
    padding: 8px 20px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    & > .aj-book-session-content {
      & > p {
        font-size: 0.86rem;
        margin: 8px 0;
      }
      & > h2 {
        font-size: 1.72rem;
        font-weight: 600;
        margin: 8px 0;
      }
      & > button {
        background-color: var(--aj-dark);
        color: var(--aj-white);
        padding: 12px 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        margin: 8px 0;
      }
    }
    & > .graphics {
      background-image: url("/dashboard/stars.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
`;
