import React, { useState, useEffect } from "react";
// import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import { res_sm } from "../../settings";
import useApp from "../../store";
import {
  convertTimeToISOString,
  isValidTimeSlot,
  validTimeSlotDuration,
} from "../../utils/checkers/time";
import { db } from "../../utils/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { availabilityDefault, daysOfWeek } from "../../data/calender";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

export default function MentorAvailability() {
  //   const db = firebase.firestore();
  const { profileData } = useApp();
  const mentorId = profileData?.uid;

  const [availability, setAvailability] = useState(availabilityDefault);

  const [AddSlot, setAddSlot] = useState(false);
  const [EditSlot, setEditSlot] = useState(false);

  const [SelectedDay, setSelectedDay] = useState(daysOfWeek[0]);
  const [StartTime, setStartTime] = useState(null);
  const [EndTime, setEndTime] = useState(null);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const docRef = doc(db, "mentors_availability", mentorId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data) setAvailability(data);
        } else console.info("No data found.");
      } catch (error) {
        console.error("Error fetching availability:", error);
      }
    };
    fetchAvailability();
  }, [mentorId]);

  const closeAddSlot = () => {
    setAddSlot(false);
    setSelectedDay(daysOfWeek[0]);
    setStartTime(null);
    setEndTime(null);
  };
  const closeEditSlot = () => {
    setEditSlot(false);
    setSelectedDay(daysOfWeek[0]);
    setStartTime(null);
    setEndTime(null);
  };

  const handleAddSlot = async (day) => {
    const newSlot = {
      // startRaw: StartTime,
      start: convertTimeToISOString(StartTime),
      // endRaw: EndTime,
      end: convertTimeToISOString(EndTime),
      duration: validTimeSlotDuration(StartTime, EndTime),
    };

    const updatedSlots = [...availability[day], newSlot];
    const updatedAvailability = { ...availability, [day]: updatedSlots };

    setAvailability(updatedAvailability);

    const docRef = doc(db, "mentors_availability", mentorId);
    setDoc(
      docRef,
      JSON.parse(
        JSON.stringify({
          ...updatedAvailability,
        })
      ),
      { merge: false }
    );
    console.info("User Data Successfully Updated.");
    closeAddSlot();
  };

  const handleDeleteSlot = (day, index) => {
    // const newSlots = [...availability[day]].splice(index, 1);
    // setAvailability(newSlots);
    const newSlots = { ...availability };
    let sa = [...newSlots[day]];
    if (sa.length > 1) sa.splice(index, 1);
    else sa = [];
    newSlots[day] = sa;
    const docRef = doc(db, "mentors_availability", mentorId);
    setDoc(
      docRef,
      JSON.parse(
        JSON.stringify({
          ...newSlots,
        })
      ),
      { merge: false }
    );
    setAvailability(newSlots);
  };

  return (
    <Container>
      <div className="title">
        <h2>My Availability</h2>
        <button
          className="aj-btn-primary"
          onClick={() => setAddSlot((p) => !p)}
        >
          Add Slot
        </button>
      </div>
      <div className="day-grid-container">
        <div className="day-grid">
          {daysOfWeek.map((day) => (
            <div className="day" key={day}>
              <h3>{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
              {availability[day].length === 0 && <p>No slots</p>}
              {availability[day].map((slot, index) => (
                <div className="availability" key={index}>
                  <p>
                    {new Date(slot.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    -
                    {new Date(slot.end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    <br />
                    {" ("}
                    {slot.duration} min)
                  </p>
                  <button
                    onClick={() => {
                      handleDeleteSlot(day, index);
                    }}
                  >
                    Delete
                  </button>
                  {/* <button
                    onClick={() => {
                      setEndTime(
                        new Date(slot.end).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      );
                      setStartTime(
                        new Date(slot.start).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      );
                      setSelectedDay(day);
                      setEditSlot(true);
                    }}
                  >
                    Edit
                  </button> */}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {AddSlot && (
        <div className="add-slot">
          <form className="form">
            <h3 className="title">Add a Slot</h3>
            <div className="element row">
              <label htmlFor="select-day">Select Day</label>
              <select
                name="select-day"
                id="select-day"
                value={SelectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
              >
                {React.Children.toArray(
                  daysOfWeek.map((day) => (
                    <option value={day}>
                      {day[0].toUpperCase() + day.substring(1, day.length)}
                    </option>
                  ))
                )}
              </select>
            </div>
            <div className="two">
              <div className="element">
                <label htmlFor="start-time">Start Time</label>
                <input
                  type="time"
                  name="start-time"
                  id="start-time"
                  value={StartTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
              <div className="element">
                <label htmlFor="end-time">End Time</label>
                <input
                  type="time"
                  name="end-time"
                  id="end-time"
                  value={EndTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>
              {StartTime &&
                EndTime &&
                !isValidTimeSlot(StartTime, EndTime, availability[SelectedDay])
                  .verdict && (
                  <div className="error">
                    {
                      isValidTimeSlot(
                        StartTime,
                        EndTime,
                        availability[SelectedDay]
                      ).message
                    }
                  </div>
                )}
            </div>
            <div className="cta">
              <button
                className="aj-btn-secondary-outline"
                onClick={closeAddSlot}
              >
                Cancel
              </button>
              <button
                className="aj-btn-primary"
                onClick={() => handleAddSlot(SelectedDay)}
                disabled={
                  !(
                    SelectedDay &&
                    StartTime &&
                    EndTime &&
                    isValidTimeSlot(
                      StartTime,
                      EndTime,
                      availability[SelectedDay]
                    ).verdict
                  )
                }
              >
                Save Slot
              </button>
            </div>
          </form>
        </div>
      )}
      {EditSlot && (
        <div className="add-slot">
          <form className="form">
            <h3 className="title">Edit Slot</h3>
            <div className="two">
              <div className="element">
                <label htmlFor="start-time">Start Time</label>
                <input
                  type="time"
                  name="start-time"
                  id="start-time"
                  value={StartTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
              <div className="element">
                <label htmlFor="end-time">End Time</label>
                <input
                  type="time"
                  name="end-time"
                  id="end-time"
                  value={EndTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>
              {StartTime &&
                EndTime &&
                !isValidTimeSlot(StartTime, EndTime, availability[SelectedDay])
                  .verdict && (
                  <div className="error">
                    {
                      isValidTimeSlot(
                        StartTime,
                        EndTime,
                        availability[SelectedDay]
                      ).message
                    }
                  </div>
                )}
            </div>
            <div className="cta">
              <button
                className="aj-btn-secondary-outline"
                onClick={closeEditSlot}
              >
                Cancel
              </button>
              <button
                className="aj-btn-secondary-outline"
                onClick={closeEditSlot}
              >
                Delete Slot
              </button>
              <button
                className="aj-btn-primary"
                // onClick={() => handleAddSlot(SelectedDay)}
                disabled={
                  !(
                    SelectedDay &&
                    StartTime &&
                    EndTime &&
                    isValidTimeSlot(
                      StartTime,
                      EndTime,
                      availability[SelectedDay]
                    ).verdict
                  )
                }
              >
                Save Slot
              </button>
            </div>
          </form>
        </div>
      )}
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  position: relative;
  height: 100%;
  @media (max-width: ${res_sm}px) {
  }
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.75rem;
    backdrop-filter: blur(20px);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    & > h2 {
      font-size: 1.75rem;
    }
  }
  & > .day-grid-container {
    width: 100%;
    overflow: auto;
    flex-grow: 1;
    & > .day-grid {
      display: grid;
      grid-template-columns: repeat(7, minmax(120px, 1fr));
      border: 1px solid var(--aj-gray-400);
      width: max-content;
      & > .day {
        &:not(:last-child) {
          border-right: 1px solid var(--aj-gray-400);
        }
        & > * {
          padding: 0.7rem;
        }
        & > h3 {
          font-size: 1.25rem;
          border-bottom: 1px solid var(--aj-gray-400);
        }
        & > .availability {
          &:not(:last-child) {
            border-bottom: 1px solid var(--aj-gray-400);
          }
        }
      }
    }
  }
  & > .add-slot {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    display: flex;
    padding: 24px;
    & > .form {
      width: min(400px, 100%);
      min-height: 100px;
      margin: auto;
      background-color: var(--aj-white);
      border-radius: 24px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 0.875rem;
      & > .title {
        font-size: 1.25rem;
        font-weight: 600;
      }
      & > .element,
      & > .two > .element {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        width: 100%;
        &.row {
          flex-direction: row;
          gap: 0.5rem;
        }
        & > label {
          font-size: 0.925rem;
          font-weight: 500;
          &::after {
            content: ":";
          }
        }
        & > select,
        & > input {
          width: 100%;
          flex: 1;
          border-radius: 12px;
          padding: 2px 8px;
          border: 1px solid var(--aj-gray-400);
        }
      }
      & > .error,
      & > * > .error {
        font-size: 0.875rem;
        color: var(--aj-primary);
      }
      & > .two {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem;
        & > .error {
          grid-column: 1 / span 2;
        }
      }
      & > .cta {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: flex-end;
        & > button {
          padding: 0.5rem 0.875rem;
          &:disabled {
            opacity: 0.6;
          }
        }
      }
    }
  }
`;
