import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase";
import styled from "styled-components";
import BottomGraphics from "../components/BottomGraphics";
// import { MentorServices } from "../data/MentorServiceData";
import MentorCommon from "../components/blocks/MentorCommon";
import { processProfileData } from "../store/hepler/processProfileData";
import { Calendar } from "../components/Calendar";

export default function SingleMentorPage() {
  // const navigate = useNavigate();
  const path = useParams();
  const uid = path.mentor?.replace("abacas", "").replace("s-rdfsNk", "");
  const [Data, setData] = useState();
  // const [Services, setServices] = useState();
  // const MS = MentorServices;

  useEffect(() => {
    const func = async () => {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setData(processProfileData(data, true));
        // let ser = [];
        // for (let el of MS) {
        //   if (data?.services?.includes(el.value)) {
        //     ser.push(el);
        //   }
        // }
        // setServices(ser);
      }
    };
    func();
  }, [uid]);

  // console.log(Data, uid);

  return (
    <>
      <Container>
        <h1 className="title aj-font-roboto">
          I'm <span>{Data?.displayName?.split(" ")?.[0]},</span>
          <br />
          <em>from </em>
          {Data?.currentUniversity}
        </h1>

        <MentorCommon Data={Data} />

        <Calendar mentorId={uid} data={Data} />
      </Container>
      <BottomGraphics />
    </>
  );
}

const Container = styled.main`
  width: min(1440px, 100%);
  margin: 0 auto;
  padding: 24px 48px;
  & > .title {
    text-align: center;
    font-size: 6rem;
    font-weight: 700;
    font-style: italic;
    & > span {
      color: var(--aj-primary);
    }
    & > em {
      font-size: 2rem;
    }
  }
  & > .image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 52px 0;
    & > .image {
      position: relative;
      width: min(500px, 90%);
      aspect-ratio: 1/1;
      & > .profile-image {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
        border: 8px solid transparent;
        outline: 1px solid var(--aj-primary);
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > .IELTS {
        position: absolute;
        left: -10%;
        & > svg {
          width: min(257px, 50%);
          aspect-ratio: 257/163;
          @media (max-width: 760px) {
            width: 30%;
          }
        }
        @media (max-width: 760px) {
          left: -5%;
        }
      }
      & > .hello {
        position: absolute;
        top: -36px;
        right: -32px;
        display: flex;
        align-items: top;
        justify-content: flex-end;
        & > svg {
          width: min(193px, 50%);
          aspect-ratio: 193/81;
        }
        @media (max-width: 760px) {
          right: -0%;
        }
      }
      & > .Sat {
        position: absolute;
        top: 48px;
        left: 97%;
        display: flex;
        align-items: top;
        justify-content: flex-end;
        & > svg {
          width: 209px;
          aspect-ratio: 209/133;
          @media (max-width: 760px) {
            width: 30%;
          }
        }
        @media (max-width: 950px) {
          left: auto;
          right: -26%;
        }
        @media (max-width: 760px) {
          right: -10%;
        }
      }
    }
  }
  & > .info {
    & > h2 {
      text-align: center;
      font-size: 2.4rem;
    }
    & > h3 {
      text-align: center;
      font-size: 1rem;
    }
    & > .about {
      padding: 36px 32px 24px;
      width: min(1173px, 100%);
      margin: 32px auto;
      box-shadow: 0 0 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      & > h3 {
        font-size: 2rem;
        font-style: italic;
        margin-bottom: 12px;
      }
      & > p {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    & > .services {
      padding: 24px 0;
      width: min(700px, 100%);
      margin: 0 auto;
      & > h3 {
        font-size: 2rem;
        font-style: italic;
        margin-bottom: 12px;
      }
      & > .chips {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: 780px) {
        }
        & > .chip {
          padding: 24px 16px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          @media (max-width: 580px) {
            padding: 20px 12px;
          }
          & > .img {
            font-size: 3rem;
          }
        }
      }
    }
  }
`;
