import React from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";

export default function ModalWrapper({ show, onClose, title, children }) {
  return (
    show && (
      <Container>
        <div className="bg" role="button" tabIndex={0} onClick={onClose}></div>
        <div className="body">
          <div className="title">
            <h2>{title}</h2>
            <button onClick={onClose}>
              <MdClose />
            </button>
          </div>
          {children}
        </div>
      </Container>
    )
  );
}

const Container = styled.div`
  position: fixed;
  inset: 0;
  width: 100dvw;
  height: 100dvh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  & > .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  & > .body {
    position: static;
    z-index: 2;
    width: min(600px, 100%);
    background-color: var(--aj-white);
    border-radius: 16px;
    padding: 20px;
    & > .title {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 20px;
      & > h2 {
        font-size: 1.5rem;
      }
      & > button {
        font-size: 1.5rem;
        margin-left: auto;
      }
    }
  }
`;
