import React, { useEffect, useState } from "react";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";
import LegendTextarea from "../../input/LegendTextarea";
import LegendInput from "../../input/LegendInput";
import { handleImageUpload } from "../../../utils/uploader/photoUpload";

export default function BasicInfo({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();

  // not related to form field start
  const [Uploading, setUploading] = useState(false);
  const [ImageError, setImageError] = useState(false);
  // not related to form field end

  const [photoURL, setPhotoURL] = useState(profileData?.photoURL);
  const [FirstName, setFirstName] = useState(profileData?.firstName);
  const [LastName, setLastName] = useState(profileData?.lastName);
  const [Email, setEmail] = useState(profileData?.email);
  const [Address, setAddress] = useState(profileData?.address);
  const [City, setCity] = useState(profileData?.city);
  const [BirthDay, setBirthDay] = useState(profileData?.birthday);
  const [Nationality, setNationality] = useState(profileData?.nationality);
  const [LastEducation, setLastEducation] = useState(
    profileData?.lastEducation
  );
  const [LastInstitution, setLastInstitution] = useState(
    profileData?.lastInstitution
  );
  const [ThreeFacts, setThreeFacts] = useState(
    profileData?.threeFacts?.join("\n")
  );

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      photoURL,
      firstName: FirstName,
      lastName: LastName,
      email: Email,
      address: Address,
      city: City,
      birthday: BirthDay,
      nationality: Nationality,
      lastEducation: LastEducation,
      lastInstitution: LastInstitution,
      threeFacts: ThreeFacts?.split("\n"),
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    UpdateUserData(uid, data, setLoading, setError, changeStep);
  }

  useEffect(() => {
    if (photoURL && !Uploading && !ImageError) {
      updateUser({ ...profileData, photoURL });
      UpdateUserData(
        profileData?.uid,
        { photoURL },
        setUploading,
        setImageError,
        () => {}
      );
    }
  }, [photoURL, Uploading, ImageError, profileData?.uid]);

  return (
    <form onSubmit={handleSubmit} className="basic-info-form">
      <div className="with-image">
        <div className="profile">
          <div className="profile-image">
            <input
              type="file"
              name="prodPictures"
              id="prodPictures"
              accept="image/png, image/jpeg, image/jpg, image/webp"
              onChange={(e) =>
                handleImageUpload(
                  e,
                  profileData?.uid,
                  setPhotoURL,
                  setUploading,
                  setImageError
                )
              }
            />
            {photoURL ? (
              <>
                <img src={photoURL} alt={`${FirstName} ${LastName} profile`} />
                <div className="hover">
                  <p>
                    Choose a photo
                    <br />
                    <span>smaller than 1MB</span>
                  </p>
                </div>
              </>
            ) : (
              <p>
                Choose a photo
                <br />
                <span>smaller than 1MB</span>
              </p>
            )}
          </div>
          {ImageError && <small>{ImageError}</small>}
        </div>
        <div className="fields">
          <div className="two-col">
            <LegendInput
              label={"First Name"}
              placeholder="John"
              value={FirstName}
              setValue={setFirstName}
            />
            <LegendInput
              label={"Last Name"}
              placeholder="Doe"
              value={LastName}
              setValue={setLastName}
            />
          </div>
          <LegendInput
            label={"Email"}
            placeholder="example@example.com"
            type="email"
            value={Email}
            setValue={setEmail}
          />
        </div>
      </div>
      <LegendInput
        label={"Address"}
        placeholder="Your Address"
        value={Address}
        setValue={setAddress}
      />
      <LegendInput
        label={"City"}
        placeholder="Your City"
        value={City}
        setValue={setCity}
      />
      <LegendInput
        label={"Birthday"}
        placeholder="DD / MM / YYYY"
        value={BirthDay}
        setValue={setBirthDay}
      />
      <LegendInput
        label={"Nationality"}
        placeholder="Your Nationality"
        value={Nationality}
        setValue={setNationality}
      />
      <LegendInput
        label={"Highest Level of Education"}
        placeholder="A Level"
        value={LastEducation}
        setValue={setLastEducation}
      />
      <LegendInput
        label={"Institution"}
        placeholder="Your Institution"
        value={LastInstitution}
        setValue={setLastInstitution}
      />
      <LegendTextarea
        label={"Write 3 Interesting Facts About You"}
        placeholder="Facts"
        value={ThreeFacts}
        setValue={setThreeFacts}
      />
      <button
        disabled={!(photoURL && FirstName && LastName && Email) || Loading}
        type="submit"
        className="aj-btn-primary"
      >
        {Loading ? "Loading..." : "Next"}
      </button>
    </form>
  );
}
