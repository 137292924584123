import React, { useState } from "react";
import LegendInput from "../../input/LegendInput";
import useApp from "../../../store";
import { UpdateUserData } from "../../../utils/user";
import { useNavigate } from "react-router-dom";

export default function ServiceCharge({
  setLoading,
  setError,
  Loading,
  setStep,
  step,
}) {
  const { profileData, updateUser } = useApp();
  const navigate = useNavigate();

  const [M30, setM30] = useState(profileData?.rate?.m30);
  const [M60, setM60] = useState(profileData?.rate?.m60);

  function handleSubmit(e) {
    e.preventDefault();
    const uid = profileData.uid;
    const stepCompletedProfile = profileData?.stepCompletedProfile;
    const data = {
      rate: { m30: Number(M30), m60: Number(M60) },
      stepCompletedProfile:
        stepCompletedProfile &&
        (isNaN(stepCompletedProfile) || stepCompletedProfile < step)
          ? step
          : stepCompletedProfile,
    };
    const changeStep = () => {
      setStep(step + 1);
      updateUser({ ...profileData, ...data });
    };
    if (!(isNaN(M30) && isNaN(M60))) {
      UpdateUserData(uid, data, setLoading, setError, changeStep);
      navigate("/dashboard?tab=dashboard");
    } else {
      const msg = "Enter a valid numerical value.";
      setError(msg);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="two-col">
        <LegendInput
          label={"Amount in USD 30 mins"}
          placeholder="$0.00"
          type="number"
          value={M30}
          setValue={setM30}
          required={true}
        />
        <LegendInput
          label={"Amount in USD 60 mins"}
          placeholder="$0.00"
          type="number"
          value={M60}
          setValue={setM60}
          required={true}
        />
      </div>
      {/* <p style={{ textAlign: "center" }}>
        Please Consider only your tuition fee.
      </p> */}
      <button disabled={Loading} type="submit" className="aj-btn-primary">
        {Loading ? "Loading..." : "Next"}
      </button>
    </form>
  );
}
