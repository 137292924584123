import { createContext, useContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import { processProfileData } from "./hepler/processProfileData";

const Context = createContext(initialState);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userLogin = (uState, uData, uType) => {
    dispatch({
      type: "login",
      payload: uState,
    });
    dispatch({ type: "profileData", payload: processProfileData(uData) });
    dispatch({ type: "userIs", payload: uType });
    sessionStorage.loggedIn = uState ? "yes" : "no";
    sessionStorage.user = JSON.stringify(processProfileData(uData));
  };
  const userLogout = () => {
    dispatch({
      type: "login",
      payload: false,
    });
    dispatch({ type: "profileData", payload: null });
    dispatch({ type: "userIs", payload: undefined });
    sessionStorage.loggedIn = "no";
    sessionStorage.removeItem("user");
  };
  const updateUser = (data) => {
    dispatch({ type: "profileData", payload: processProfileData(data) });
    sessionStorage.user = JSON.stringify(processProfileData(data));
  };
  const updateUserSingle = (field, value) => {
    let data = { ...state?.profileData };
    data[field] = value;
    dispatch({ type: "profileData", payload: processProfileData(data) });
    sessionStorage.user = JSON.stringify(processProfileData(data));
  };
  const LastUpdated = (data) => {
    dispatch({
      type: "lastUpdated",
      payload: data,
    });
  };
  const setGlobSlotInfo = (data) => {
    dispatch({
      type: "setSlot",
      payload: data,
    });
  };

  const value = {
    ...state,
    userLogin,
    userLogout,
    updateUser,
    updateUserSingle,
    LastUpdated,
    setGlobSlotInfo,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useApp = () => {
  const context = useContext(Context);
  return context;
};

export default useApp;
