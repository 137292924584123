import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import useApp from "../../store";
import { res_md } from "../../settings";
import { NavItems } from "../../data/dashboardCommons";

export default function DHeader({
  OpenLeftMenu,
  setOpenLeftMenu,
  OpenRightMenu,
  setOpenRightMenu,
}) {
  const { profileData } = useApp();

  return (
    <Container className="">
      <div className="content">
        <button
          className={OpenLeftMenu ? "active" : ""}
          onClick={() => setOpenLeftMenu((e) => !e)}
        >
          <span className="ico"></span>
        </button>

        <Link to={NavItems.left.href}>
          <span className="logo_con">
            <img src={NavItems.left.logo} alt={NavItems.left.alt} />
          </span>
        </Link>

        <nav className="nav_right">
          {/* <button onClick={() => setOpenRightMenu((e) => !e)}>
            <img
              src={
                profileData?.photoURL
                  ? profileData.photoURL
                  : "/dashboard/dummy_profile.png"
              }
              alt={
                profileData?.displayName
                  ? profileData.displayName
                  : "User name not found"
              }
            />
          </button> */}
        </nav>
      </div>
    </Container>
  );
}
const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  /* background-color: #fff; */
  width: min(1440px, 100%);
  margin: 0 auto;
  /* box-shadow: 0px 5px 42px 0 var(--aj-light-dark-shadow); */
  & > .content {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > a {
      @media (min-width: ${res_md + 1}px) {
        visibility: hidden;
      }
      & > .logo_con {
        overflow: hidden;
        position: relative;
        display: block;
        width: min(70px, 8rem);
        aspect-ratio: 96.828/36;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    & > nav {
      &.nav {
        &_right {
          display: flex;
          align-items: center;
          gap: 8px;
          & > button {
            width: 26px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
    & > button {
      display: none;
      @media (max-width: 780px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px;
        width: 28px;
        aspect-ratio: 1/1;
        background-color: transparent;
        border: none;
        outline: none;
        & > span {
          height: 2px;
          width: 100%;
          position: relative;
          background-color: var(--aj-gray-500);
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: -6px;
            left: 0;
            background-color: var(--aj-gray-500);
          }
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -6px;
            left: 0;
            background-color: var(--aj-gray-500);
          }
        }
        &.active {
          & > span {
            /* opacity: 0; */
            background-color: transparent;
            &::before {
              top: 0;
              transform-origin: center;
              transform: rotate(-45deg);
            }
            &::after {
              bottom: 0;
              transform-origin: center;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
`;
