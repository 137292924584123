import {
  applyActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export function RegisterUser(
  e,
  FirstName,
  LastName,
  Email,
  Password,
  ReEnterPassword,
  setLoading,
  setError,
  userLogin,
  navigate,
  userType = "student"
) {
  e.preventDefault();
  setLoading(true);
  if (Email && String(Email).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
    if (
      Email &&
      Password &&
      Password.length > 7 &&
      ReEnterPassword &&
      Password === ReEnterPassword
    ) {
      createUserWithEmailAndPassword(auth, Email, Password)
        .then((userCredential) => {
          const user = userCredential.user;
          setError(undefined);

          try {
            sendEmailVerification(user).then(() => {
              console.info(
                "A verification mail is sent to your provided email address"
              );
            });
          } catch (error) {
            console.error(error);
          }

          try {
            updateProfile(user, {
              displayName: `${FirstName} ${LastName}`,
            })
              .then(() => {
                console.info(
                  `${FirstName} ${LastName} name successfully added.`
                );
              })
              .catch((err) => console.error(err));
          } catch (error) {
            console.error(error);
          }

          try {
            const docRef = doc(db, "users", user.uid);
            setDoc(
              docRef,
              {
                uid: auth.currentUser.uid,
                displayName: `${FirstName} ${LastName}`,
                email: user.email,
                firstName: FirstName,
                lastName: LastName,
                userType,
                acceptedTAndC: true,
                stepCompletedProfile: 0,
              },
              { merge: true }
            );
            console.info("User successfully created.");
          } catch (err) {
            console.error(err);
          }
          userLogin(
            true,
            {
              ...user,
              uid: auth.currentUser.uid,
              displayName: `${FirstName} ${LastName}`,
              email: user.email,
              firstName: FirstName,
              lastName: LastName,
              userType: "student",
              acceptedTAndC: true,
              profileStep: 0,
            },
            "student"
          );
          setError();
          setLoading(false);
          if (sessionStorage?.comingFrom) navigate(sessionStorage.comingFrom);
          else navigate("/dashboard");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorMessage);
          setError(errorCode);
          setLoading(false);
        });
    } else {
      setError("One or more field is not properly filled.");
      setLoading(false);
    }
  } else {
    setError("Please enter a valid email.");
    setLoading(false);
  }
}

export function LogInUser(
  e,
  Email,
  Password,
  userLogin,
  setLoading,
  setError,
  navigate
) {
  e.preventDefault();
  setLoading(true);
  if (Email && Password && Password.length > 7) {
    signInWithEmailAndPassword(auth, Email, Password)
      .then((userCredential) => {
        const user = userCredential.user;
        setError();

        async function dataCall() {
          let uType = { userType: "student" };
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            uType = docSnap.data();
          }
          return uType;
        }

        Promise.resolve(dataCall()).then((value) => {
          userLogin(true, { ...user, ...value }, value.userType.toLowerCase());
          setError();
          setLoading(false);
          if (sessionStorage?.comingFrom) navigate(sessionStorage.comingFrom);
          else navigate("/dashboard/");
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
        setError(errorCode);
        setLoading(false);
      });
  } else {
    setError("Password is not long long enough.");
    setLoading(false);
  }
}

export function LogUserOut(logout) {
  signOut(auth)
    .then(() => {
      logout();
    })
    .catch((error) => {
      logout();
      console.error(error);
    });
}

export function sendVerificationEmail(e, Sent, setSent) {
  e.preventDefault();
  // const actionCodeSettings = {
  //   url: "https://abroadmates.com/__/auth/action", // Custom URL
  //   handleCodeInApp: true, // Ensures redirection to the app
  // };
  !Sent &&
    sendEmailVerification(auth.currentUser).then(() => {
      setSent(true);
    });
}

export function verifySentEmailAction(type, code) {
  const res = {};
  switch (type) {
    case "verifyEmail":
      applyActionCode(auth, code)
        .then(() => {
          res.code = 200;
          res.message = "Email verified successfully.";
        })
        .catch((error) => {
          res.code = 400;
          res.message = JSON.stringify(error);
        });
      break;
    case "resetPassword":
      verifyPasswordResetCode(auth, code)
        .then(() => {
          res.code = 200;
          res.message = "Password Reset successfully.";
        })
        .catch((error) => {
          res.code = 400;
          res.message = JSON.stringify(error);
        });
      break;
    default:
      res.code = 404;
      res.message = "Unknown verify type";
      break;
  }
  return res;
}

export function UpdateUserData(
  id,
  data = {},
  setLoading,
  setError,
  onSuccess = () => {},
  merge = true
) {
  setLoading(true);
  try {
    const docRef = doc(db, "users", id);
    setDoc(
      docRef,
      JSON.parse(
        JSON.stringify({
          ...data,
        })
      ),
      { merge }
    );
    console.info("User Data Successfully Updated.");
    setError();
    onSuccess();
  } catch (err) {
    console.error(err);
    setError(err);
  } finally {
    setLoading(false);
  }
}
