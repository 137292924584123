import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoSearchSharp } from "react-icons/io5";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import Cards from "../components/Cards";
import BottomGraphics from "../components/BottomGraphics";
import { HowItWorks } from "../data/Mentors";
import BrandLoading from "../components/BrandLoading";

export default function MentorsGridPage() {
  const [SearchText, setSearchText] = useState();
  const [Data, setData] = useState([]);
  const [UsableData, setUsableData] = useState();
  const [Loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCallSeries() {
        let data = [];
        const q = query(
          collection(db, "users"),
          where("userType", "==", "mentor")
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        return data;
      }

      Promise.resolve(dataCallSeries()).then((value) => {
        setData(value);
        setUsableData(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (Data && Array.isArray(Data) && Data.length > 0) {
      if (SearchText && SearchText.length > 0) {
        let data = [...Data];
        let some = data.filter((el) =>
          el?.displayName?.toLowerCase().includes(SearchText?.toLowerCase())
        );
        setUsableData(some);
      } else {
        setUsableData(Data);
      }
    }
  };

  // useEffect(() => {
  //   handleSearch();
  // }, [SearchText,ha]);

  return (
    <>
      <Container>
        <section className="hero">
          <div className="bg">
            <div className="img">
              <img src="/images/mates-hero.png" alt="mates hero banner" />
            </div>
            <div className="search">
              <div className="input">
                <input
                  type="text"
                  name="Search"
                  id="Search"
                  value={SearchText}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search your mentors....."
                />
                <span>
                  <IoSearchSharp />
                </span>
              </div>
            </div>
          </div>
        </section>
        {UsableData && Array.isArray(UsableData) && UsableData.length > 0 && (
          <section className="mentors">
            <h2>
              {SearchText
                ? `Found ${UsableData.length} Mentors for ${SearchText}`
                : "Featured Mentors:"}
            </h2>
            <div className="grid">
              {React.Children.toArray(
                UsableData.map((item) => (
                  <Cards Data={item} id={item?.id} transfer={true} />
                ))
              )}
            </div>
          </section>
        )}
        <section className="works">
          <h2>
            How it <span>works</span>?
          </h2>
          <div className="grid">
            {React.Children.toArray(
              HowItWorks.map((item) => (
                <div className="item">
                  <div className="image">
                    <img src={item?.image} alt={item?.title} />
                  </div>
                  <h3 className="title">{item?.title}</h3>
                  <p className="sub">{item?.subtitle}</p>
                </div>
              ))
            )}
          </div>
        </section>
        <BottomGraphics />
      </Container>
      <BrandLoading loading={Loading} />
    </>
  );
}

const Container = styled.main`
  & > .hero {
    padding: 0 24px;
    & > .bg {
      width: min(1326px, 100%);
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > .img {
        width: min(637px, 100%);
        aspect-ratio: 637/277;
        margin: 0 auto;
        & > img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          /* border-radius: 24px;
          position: absolute;
          inset: 0;
          z-index: 0; */
        }
      }
      & > .search {
        position: relative;
        z-index: 1;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: min(730px, 100%);
        & > h1 {
          font-size: 3.75rem;
          font-weight: 500;
          font-style: italic;
          text-align: center;
          color: var(--aj-white-100);
        }
        & > .input {
          width: min(630px, 90%);
          padding: 8px;
          padding: 4px;
          background-color: var(--aj-primary);
          border-radius: 8px;
          display: flex;
          align-self: center;
          gap: 4px;
          & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--aj-white-100);
            border-radius: 7px;
            padding: 8px;
            /* background-color: var(--aj-primary); */
            color: var(--aj-primary);
            font-size: 1.5rem;
          }
          & > input {
            background-color: transparent;
            border: none;
            outline: none;
            color: var(--aj-white-100);
            flex: 1;
            padding: 0 8px;
            &:focus {
              box-shadow: none;
            }
            &::placeholder {
              color: var(--aj-white-100);
            }
          }
        }
      }
    }
  }
  & > .mentors {
    width: min(1320px, 100%);
    padding: 48px 24px;
    margin: 0 auto;
    & > h2 {
      font-size: 2.5rem;
      font-weight: 700;
      text-align: left;
      & > span {
        color: var(--aj-primary);
      }
    }
    & > .filtration {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin: 24px 0;
      & > .search {
        padding: 4px;
        background-color: var(--aj-primary);
        border: 1.2px solid var(--aj-dark);
        border-radius: 8px;
        display: flex;
        align-self: center;
        gap: 4px;
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--aj-white-100);
          border-radius: 4px;
          padding: 2px;
          color: var(--aj-primary);
        }
        & > input {
          background-color: transparent;
          border: none;
          outline: none;
          color: var(--aj-white-100);
          &:focus {
            box-shadow: none;
          }
        }
      }
      & > .filter_trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--aj-primary);
        padding: 6px;
        color: var(--aj-white-100);
        border: 1.2px solid var(--aj-dark);
        border-radius: 8px;
      }
    }
    & > .grid {
      padding-top: 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
    }
  }
  & > .works {
    width: min(1320px, 100%);
    padding: 48px 24px;
    margin: 0 auto;
    & > h2 {
      font-size: 3rem;
      text-align: center;
      font-weight: 800;
      text-transform: capitalize;
      & > span {
        /* color: var(--aj-primary); */
      }
    }
    & > .grid {
      padding-top: 3rem;
      display: grid;
      gap: 40px;
      width: min(1172px, 100%);
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
      & > .item {
        background-image: linear-gradient(
          135deg,
          #da343b 0%,
          #b62329 37%,
          #8f1116 100%
        );
        padding: 4.5rem 24px 2rem;
        border-radius: 4rem;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
        & > .image {
          width: min(134px, 100%);
          aspect-ratio: 274/214;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        & > .title {
          color: var(--aj-white-100);
          width: min(100%, 200px);
          text-align: center;
          text-transform: capitalize;
          font-size: 2rem;
        }
        & > .sub {
          color: var(--aj-white-100);
          text-align: center;
          font-size: 1.25rem;
          width: min(250px, 100%);
        }
      }
    }
  }
`;
