import React, { useState } from "react";
import styled from "styled-components";
import StudentDashboard from "../DashboardStudent";
import AdminDashboard from "../DashboardAdmin";
import MentorDashboard from "../DashboardMentor";

export default function DashboardSudo() {
  const [Show, setShow] = useState("admin");

  const Views = {
    admin: <AdminDashboard />,
    mentor: <MentorDashboard />,
    requested: <MentorDashboard />,
    student: <StudentDashboard />,
  };

  return (
    <Container>
      <div className="tabs">
        <h2>SUDO Options:</h2>
        {React.Children.toArray(
          [...Object.keys(Views)].map((el) => (
            <button
              className={el === Show ? "active" : ""}
              onClick={() => setShow(el)}
            >
              {el}
            </button>
          ))
        )}
      </div>
      <div className="page">{Views[Show]}</div>
    </Container>
  );
}

const Container = styled.main`
  background-color: var(--aj-gray-200);
  & > .tabs {
    background-color: var(--aj-gray-200);
    position: sticky;
    top: 0;
    z-index: 300;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    overflow-x: auto;
    padding: 8px 20px;
    & > h2 {
      font-size: 1.25rem;
    }
    & > button {
      background-color: var(--aj-white-100);
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      padding: 8px 24px;
      color: var(--aj-primary);
      text-transform: capitalize;
      &.active {
        background-color: var(--aj-primary);
        color: var(--aj-white);
      }
    }
  }
  & > .page {
    overflow-y: auto;
  }
`;
