export const GETUTCDateString = (date = new Date()) => {
  const string = `${date.getUTCDate()}/${date.getUTCMonth()}/${date.getUTCFullYear()}`;
  return string;
};

export function generateBookingSlots(
  slot,
  bookingDuration,
  forDate,
  step = 15
) {
  // Use forDate as the reference date
  const today = new Date(forDate.toISOString());

  // Parse the original slot start and end times
  const origStart = new Date(slot.start);
  const origEnd = new Date(slot.end);

  // Extract the date parts (YYYY-MM-DD) from the ISO strings
  const startDatePart = slot.start.split("T")[0];
  const endDatePart = slot.end.split("T")[0];

  let normalizedStart, normalizedEnd;

  if (startDatePart === endDatePart) {
    // The slot is within a single day.
    // Normalize both start and end to the reference date (today)
    normalizedStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      origStart.getHours(),
      origStart.getMinutes(),
      origStart.getSeconds(),
      origStart.getMilliseconds()
    );
    normalizedEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      origEnd.getHours(),
      origEnd.getMinutes(),
      origEnd.getSeconds(),
      origEnd.getMilliseconds()
    );
  } else {
    // The slot spans two days.
    // Assume the start time belongs to the day before the reference date,
    // and the end time belongs to the reference date.
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    normalizedStart = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      origStart.getHours(),
      origStart.getMinutes(),
      origStart.getSeconds(),
      origStart.getMilliseconds()
    );
    normalizedEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      origEnd.getHours(),
      origEnd.getMinutes(),
      origEnd.getSeconds(),
      origEnd.getMilliseconds()
    );
  }

  // If the normalized start is not on the same local date as today,
  // force it to today at the original start time.
  if (normalizedStart.toLocaleDateString() !== today.toLocaleDateString()) {
    normalizedStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      origStart.getHours(),
      origStart.getMinutes(),
      origStart.getSeconds(),
      origStart.getMilliseconds()
    );
  }

  console.log(
    "Slot:",
    slot,
    "\nReference Date:",
    today.toLocaleDateString(),
    "\nNormalized Start:",
    normalizedStart.toLocaleString(),
    "\nNormalized End:",
    normalizedEnd.toLocaleString()
  );

  const slots = [];
  // Determine the latest possible start time for a booking of the given duration
  const latestStartTime = normalizedEnd.getTime() - bookingDuration * 60000;

  // For continuous slots (no gap), use the bookingDuration as step.
  const stepUsing = bookingDuration;

  let currentTime = normalizedStart.getTime();

  console.log(
    "Current Time:",
    currentTime,
    "Latest Start Time:",
    latestStartTime
  );

  // Generate slots by advancing the currentTime by stepUsing minutes until we reach the latest start time.
  while (currentTime <= latestStartTime) {
    const slotStart = new Date(currentTime);
    const slotEnd = new Date(currentTime + bookingDuration * 60000);
    slots.push({
      start: slotStart.toISOString(),
      end: slotEnd.toISOString(),
    });
    currentTime += stepUsing * 60000;
  }

  return slots;
}

export function getCurrentWeekNumber() {
  const today = new Date();
  const d = new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNo;
}
